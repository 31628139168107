import React, { FC } from "react"

import { Button } from "react-bootstrap"
import * as s from "./Quantity.module.scss"

type Props = {
  onUpdateQuantity: any
  quantity: number
}

const Quantity: FC<Props> = ({ onUpdateQuantity, quantity }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = (e.target.value || quantity).toString()
    onUpdateQuantity(parseInt(newQuantity, 10))
  }

  return (
    <div className={s.container}>
      <Button
        className={s.noBorderButton}
        variant="outline-dark"
        size="sm"
        onClick={() => onUpdateQuantity(quantity - 1)}
      >
        -
      </Button>
      <input
        value={quantity}
        className={s.input}
        onChange={onChange}
        type="num"
      />
      <Button
        className={s.noBorderButton}
        variant="outline-dark"
        size="sm"
        onClick={() => onUpdateQuantity(quantity + 1)}
      >
        +
      </Button>
    </div>
  )
}

export default Quantity
