// extracted by mini-css-extract-plugin
export var back = "CartContainer-module--back--182ZM";
export var buttonContainer = "CartContainer-module--buttonContainer--x6pZN";
export var checkoutBtn = "CartContainer-module--checkoutBtn--QhvBZ";
export var container = "CartContainer-module--container--dOpod";
export var couponContainer = "CartContainer-module--couponContainer--ff6IJ";
export var ctas = "#226355";
export var discountsContainer = "CartContainer-module--discountsContainer--TO9ha";
export var grayBackground = "#f6f8fb";
export var line = "CartContainer-module--line--syaen";
export var price = "CartContainer-module--price--2tfhf";
export var title = "CartContainer-module--title--qqirF";
export var titlePrice = "CartContainer-module--titlePrice--UnA9p";
export var totalContainer = "CartContainer-module--totalContainer--x-zQs";
export var totalLine = "CartContainer-module--totalLine--f6s7a";