import React, { useContext } from "react"
import { Container } from "react-bootstrap"
import FullyRoundedDarkButton from "@components/common/Buttons/FullyRoundedDarkButton"
import LineItem from "../LineItem"
import { Link } from "gatsby"
import { ShopContext } from "@contexts/shop"
import TextWithBackArrow from "@components/common/TextWithBackArrow"
import * as s from "./CartContainer.module.scss"

const CartContainer = () => {
  const { checkout } = useContext(ShopContext)
  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const hasDiscounts =
    checkout?.discountApplications && !!checkout?.discountApplications?.length

  const lineItems = checkout?.lineItems.map((item) => (
    <LineItem key={item.id.toString()} item={item} />
  ))

  if (!checkout?.lineItems.length) {
    return (
      <Container className={s.container}>
        <h2>No products in cart currently</h2>
        <Link to="/">Go to home page</Link>
      </Container>
    )
  }

  return (
    <Container className={s.container}>
      <h2 className={s.title}>Shopping Cart</h2>
      {lineItems}
      <div className={s.totalContainer}>
        <Link className="no-underline" to="/">
          <TextWithBackArrow
            style={{ fontSize: "1.25rem" }}
            text="Continue Shopping"
          />
        </Link>
        <div className={s.pricesContainer}>
          <div className={s.totalLine}>
            <p className={s.titlePrice}>Subtotal</p>
            <p className={s.price}>
              $ {parseFloat(checkout?.lineItemsSubtotalPrice.amount).toFixed(2)}
            </p>
          </div>
          {hasDiscounts && (
            <div className={s.totalLine}>
              <p className={s.titlePrice}>Discounts</p> 
              <div className={s.discountsContainer}>
                {checkout?.discountApplications.map((discount) => (
                  <p key={index} className={s.price}>{`- $ ${discount.value.amount}`}</p>
                ))}
              </div>
            </div>
          )}
          <div className={s.totalLine}>
            <p className={s.titlePrice}>Taxes</p>
            <p className={s.price}>$ {checkout?.totalTax.amount}</p>
          </div>
          <div className={s.totalLine}>
            <p className={s.titlePrice}>Total</p>
            <p className={s.price}>$ {checkout?.totalPrice.amount}</p>
          </div>
        </div>
      </div>
      <div className={s.buttonContainer}>
        <FullyRoundedDarkButton onClick={handleCheckout}>
          Continue to Checkout
        </FullyRoundedDarkButton>
      </div>
    </Container>
  )
}

export default CartContainer
