import React, { useContext } from "react"

import { Button } from "react-bootstrap"
import { Link } from "gatsby"
import Quantity from "../Quantity"
import { ShopContext } from "@contexts/shop"
import * as s from "./LineItem.module.scss"

const LineItem = (props) => {
  const { item } = props
  const { removeProductFromCart, updateQuantity } = useContext(ShopContext)
  const variantImage =
    item.variant && item.variant.image ? (
      <img
        src={item.variant.image.src}
        alt={`${item.title} product shot`}
        height="140px"
        className={s.image}
      />
    ) : null

  const handleRemove = () => {
    removeProductFromCart(item.id)
  }

  const priceForItem =
    item.variant && (parseFloat(item.variant.price.amount) * item.quantity).toFixed(2)

  return (
    <div className={s.itemRoot}>
      <div className={s.wrapper}>
        <div className={s.buttonMobileContainer}>
          <Button
            size="sm"
            variant="outline-dark"
            onClick={handleRemove}
            className={s.removeBtn}
          >
            X
          </Button>
        </div>
        <Link
          className="no-underline"
          to={`/product/${item.variant && item.variant.product.handle}/`}
        >
          {variantImage}
        </Link>
        <p className={s.textContainer}>
          <span className={s.title}>{item.title}</span>
          <br />
          <span>{item.variant && item.variant.title}</span>
        </p>
        <Quantity
          onUpdateQuantity={(q) => updateQuantity(item.id, q)}
          quantity={item.quantity}
        />
        <div className={s.title}>$ {priceForItem}</div>
        <Button
          size="sm"
          variant="outline-dark"
          onClick={handleRemove}
          className={s.removeBtn}
        >
          X
        </Button>
      </div>
    </div>
  )
}

export default LineItem
