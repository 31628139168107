import React, { FC } from "react"
import CartContainer from "@components/cart/CartContainer"
import SEO from "@components/common/SEO"

type Props = {
  location: Location
}

const CartPage: FC<Props> = ({ location }) => (
  <>
    <SEO title="Cart" location={location.href} />
    <CartContainer />
  </>
)

export default CartPage
